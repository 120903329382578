import { observer } from "mobx-react";
import React from "react";
import { InputAdornment } from "@material-ui/core";
import {
  Delete,
  EmojiEvents, ExpandLess, ExpandMore,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import CheckBox from "../../components/CheckBox";
import SelectField from "../../components/SelectField";


class ChallengeCard extends React.Component {
  state = {
    open: false
  }

  componentDidMount() {
    
  }

  render() {
    const { open } = this.state;
    const { 
      challenge, 
      challengeTypes,
      errors, 
      onChange, 
      onDelete,
    } = this.props;
    

    return (
      <div style={styles.card}>
        <div style={styles.delete}>
          <div styles={{ flex: 1 }}/>
          <div 
            style={styles.deleteBtn}
            onClick={onDelete}
          >
            <Delete />
          </div>
        </div>
        { open ? (
            <>
              <TextField
                errors={errors}
                field="name"
                item={challenge}
                label="Nome"
                placeholder="Nome"
                onChange={(e) => onChange("name", e.target.value)}
                style={styles.inputOutline}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmojiEvents color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                errors={errors}
                field="nameFr"
                item={challenge}
                label="Nome (Francês)"
                placeholder="Nome (Francês)"
                onChange={(e) => onChange("nameFr", e.target.value)}
                style={styles.inputOutline}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmojiEvents color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                errors={errors}
                field="nameEs"
                item={challenge}
                label="Nome (Espanhol)"
                placeholder="Nome (Espanhol)"
                onChange={(e) => onChange("nameEs", e.target.value)}
                style={styles.inputOutline}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmojiEvents color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <SelectField
                errors={errors}
                field="type"
                item={challenge}
                label="&nbsp; Tipo"
                list={challengeTypes}
                onChange={(e) => onChange("type", e.target.value)}
                style={styles.inputOutline}
                variant="outlined"
              />
              <TextField
                errors={errors}
                field="points"
                item={challenge}
                label="Pontos"
                placeholder="Pontos"
                onChange={(e) => onChange("points", e.target.value.replace(new RegExp("[^0-9]", "g"), ""))}
                style={styles.inputOutline}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmojiEvents color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              { !challenge.alwaysAchievable &&
                <SelectField 
                  errors={errors}
                  field="timesAchievable"
                  item={challenge}
                  label="&nbsp; Quantas vezes pode pontuar"
                  list={[
                    { label: "Uma única vez", value: 1 },
                    { label: "Dez vezes", value: 10 },
                  ]}
                  onChange={(e) => onChange("timesAchievable", e.target.value)}
                  style={styles.inputOutline}
                  variant="outlined"
                />
              }
              <div>
                <CheckBox 
                  label="Sem limite de pontuação?" 
                  field="alwaysAchievable" 
                  item={challenge} 
                />
              </div>
              <div
                onClick={() => this.setState({ open: false })}
                style={styles.showMore}
              >
                <ExpandLess /> 
              </div>
            </>
        ) : (
          <>
            <div style={styles.cardValue}>
              {!!challenge.name ? challenge.name : "Sem nome"}
            </div>
            <div style={styles.cardValue}>
              {`${!!challenge.points ? challenge.points : 0} pontos`}
            </div>
            <div
              onClick={() => this.setState({ open: true })}
              style={styles.showMore}
            >
              <ExpandMore /> 
            </div>
          </>
          )
        }
      </div>
    );
  }
}

export default observer(ChallengeCard);

const styles = {
  card: {
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "300px",
  },
  inputOutline: {
    marginBottom: "20px",
  },
  cardValue: {
    fontWeight: "700",
    fontSize: "14px",
  },
  showMore: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "12px",
  },
  delete: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  deleteBtn: {
    cursor: "pointer",
    color: "#F00",
  },
};
