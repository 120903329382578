import React from "react";
import { observer } from "mobx-react";
import List from "../../components/List";
import AuthenticationStore from "../../stores/AuthenticationStore";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import Edit from "./Edit";
import SearchField from "../../components/SearchField";
import SortPanel from "../../components/SortPanel";
import { get, jsonParser } from "../../utils/Client";
import dayjs from "dayjs";


const store = Stores.load("challenge-groups");

class ChallengeGroups extends React.Component {
  state = {
    challengeTypes: [],
  }

  componentDidMount() {
    UIStore.init("Desafios", store);

    this.load();
  }

  load = async () => {
    try{
      const challengeTypes = await get("/challenge-groups/types").then(jsonParser);
      this.setState({ challengeTypes });
    } catch(e){
      console.log(e.message ?? e);
    }
  }

  handleDelete = () => {
    let count = store.selected.length;

    UIStore.showConfirmDialog(
      `Deseja remover ${count} desafios?`,
      "Remover Desafios"
    ).then((b) => {
      if (b) {
        store
          .delete()
          .then(() => store.query())
          .catch(() => alert("Erro!"));
      }
    });
  };

  handleFilter = () => {
    store.page = 0;
    store.searchAll = !store.searchAll;
    store.query();
  };

  render() {
    const { challengeTypes } = this.state;
    const toggleFilters = (
      <ul className="filters-list">
        <li>
          <div
            className={!store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Liberados
          </div>
        </li>
        <li>
          <div
            className={store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Todos
          </div>
        </li>
      </ul>
    );
    const canEdit = AuthenticationStore.me.role !== "MANAGER";

    return (
      <>
        <List
          store={store}
          data={store.list.map((i) => ({
            item: i,
            title: (!!i.name ? i.name : "Sem nome") + ` - ${dayjs(i.startDate).format("DD/MM/YYYY")} até ${dayjs(i.validUntil).format("DD/MM/YYYY")}`,
            content: (
              <>
                Adicionado em:{" "}
                {i.createdDate ? i.createdDate.formatTimeStamp() : ""}
              </>
            ),
          }))}
          status={store.list.map((i) => ({
            id: i.id,
            label: i.enabled ? "Liberado" : "Desativado",
            class: i.enabled ? "finished-status" : "canceled-status",
          }))}
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  name: "Nome",
                  createdDate: "Data de Adição",
                  startDate: "Data de início",
                  validUntil: "Data de finalização",
                }}
              />
              <SearchField store={store} />
            </>
          }
          leftPanel={toggleFilters}
          onAdd={
            canEdit
              ? () =>
                  store.new({
                    name: "",
                    startDate: "",
                    validUntil: "",
                    challenges: [],
                    challengePrizes: [],
                    rules: "",
                    rulesFr: "",
                    rulesEs: "",
                  })
              : null
          }
          onEdit={canEdit ? () => store.open() : null}
          onDelete={canEdit ? () => this.handleDelete() : null}
        />

        <Edit 
          onSave={() => AuthenticationStore.testAuthenticated()} 
          challengeTypes={challengeTypes}  
        />
      </>
    );
  }
}

export default observer(ChallengeGroups);
